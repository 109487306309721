<template>
  <v-container class="max-container">
    <top-banner :cancel="Cancel" />
    <v-card flat color="black" class="install-app-box">
      <v-card-text>
        <div class="layout column">
          <div class="card-title-text v-card__title display-2">Install One Channel App for auto-fill passwords<br></div>
          <div class="img-box">
            <v-img src="https://www.onechannel.one/wp-content/uploads/2021/10/0222-02.png" />
          </div>
        </div>
      </v-card-text>
      <div class="card-title-text v-card__title display-2">One Channel App is needed to login these platforms</div>
      <div class="button-box">
        <v-btn
          class="text-none btn-download-ios download-app"
          color="purple"
          dark
          medium
          width="200px"
          @click="downloadApp('ios')"
        >
          iOS Download
          <v-icon
            dark
            right
          >
            mdi-apple
          </v-icon>
        </v-btn>

        <v-btn
          medium
          class="text-none btn-download-android download-app"
          color="purple"
          dark
          width="200px"
          @click="downloadApp('android')"
        >
          Android Download
          <v-icon
            dark
            right
          >
            mdi-android
          </v-icon>
        </v-btn>
        <v-btn
          medium
          class="text-none install-text-button"
          dark
          width="200px"
          text
          @click="Cancel"
        >
          I have installed
        </v-btn>
      </div>
    </v-card>
  </v-container>
</template>

<script>

import logo from '@/assets/logo1.png'
import TopBanner from './components/topBanner'

export default {
  name: 'InstallApp',
  components: {
    TopBanner
  },
  data: () => ({
    logo: logo,
    channelList: [],
    total_price: 0,
    total_regular_price: 0
  }),
  computed: {
    enableNext() {
      return this.channelList.some(item => item.check)
    }
  },
  created() {

  },
  methods: {
    Cancel() {
      this.$router.push({ name: 'mychannel' })
    },
    downloadApp(op) {
      // var utm = { 'utm_source': Cookies.get('utm_source') || 'website', 'utm_medium': Cookies.get('utm_medium'), 'utm_campaign': Cookies.get('utm_campaign') || 'web_app' }
      // var utm_query_str = Object.keys(utm).map(function(n, i) {
      //   return `${n}=${utm[n]}`
      // }).join('&')
      // var download_url = 'https://onechannel.app.link?' + utm_query_str
      var download_url = 'https://play.google.com/store/apps/details?id=com.onechannel.app'
      if (op === 'ios') {
        download_url = 'https://apps.apple.com/us/app/one-channel-plus/id1600268190'
      }
      window.open(download_url, '_blank')
    }
  }
}
</script>
<style scoped lang="scss">
.platform-text{
    font-size: 20px;
    font-weight: 320;
}

.text--primary{
    font-size: 18px;
}

.button-box{
  margin-top: 10px;
  .download-app{
    background-image: linear-gradient(45deg,#8C9EFF, #E040FB)!important;
    font-weight: bold;
    letter-spacing: 0.05em;
  }
}

.v-card__subtitle, .v-card__text, .v-card__title {
    padding: 10px;
}

.card-title-text{
  color: #eae5e5!important;
  word-break: break-word;
}

.spacer-box{
  width: 100%;
  height: 20px;
}
.button-box{
    text-align: center;
    button{
        margin: 10px;
    }
}
</style>
